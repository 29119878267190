/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b67a22c6-d45c-43da-8180-f32515d0b4b9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rk9rWbQUk",
    "aws_user_pools_web_client_id": "2mkbulbrlmhctjjffrm6deth85",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://vhb6qzax4ra3zndrq34rpwvkia.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-42xnqypwcbcp7etpl4blvkbptu",
    "aws_user_files_s3_bucket": "kashmir-website-images20431-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
